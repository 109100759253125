const isMobile = (() => {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true;
    } else {
        return false;
    }
})()

//毫秒级时间戳转Y-m-d H:i:s
function timeFormat(timeStamp) {
    const obj = timeStamp ? new Date(timeStamp) : new Date();
    var res = {
        y: obj.getFullYear(),
        m: obj.getMonth() + 1,
        d: obj.getDate(),
        h: obj.getHours(),
        i: obj.getMinutes(),
        s: obj.getSeconds()
    };
    for (const x in res) {
        res[x] = (res[x] < 10) ? '0' + res[x] : res[x];
    }
    // return res.y + "-" + res.m + "-" + res.d + " " + res.h + ":" + res.i + ":" + res.s
    return res.y + "-" + res.m + "-" + res.d;
}

function disabledScale() {
    var lastTouchEnd = 0;
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);

    // 阻止双指放大
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
    })
}

function escape2Html(str) {
    var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
    return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
    })
}

export {
    isMobile,
    timeFormat,
    disabledScale,
    escape2Html
}