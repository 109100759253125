import Vue from 'vue'
import router from './router'
import App from './App.vue'
import ElementUI from 'element-ui';
import Vant from 'vant';
import { Lazyload } from 'vant';
import store from './store';
// 视频
import VueVideoPlayer from 'vue-video-player';

// swiper所需插件
import VueAwesomeSwiper from 'vue-awesome-swiper';

// 图片预览
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.css';
import './style/style.scss';
import './style/swipper.css';
import 'swiper/swiper-bundle.css';
import vuescroll from 'vuescroll/dist/vuescroll-slide';



Vue.use(vuescroll);

// import VConsole from 'vconsole';

// const vConsole = new VConsole();  // eslint-disable-line

Vue.config.productionTip = false
Vue.use(VueViewer);
// Vue.use(VueViewer.default)

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(VueVideoPlayer);
Vue.use(VueAwesomeSwiper);
new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app');