<template>
  <div
    class="header-conrainer"
    v-show="isShow"
    :class="{ heightUp: isShowMenu }"
  >
    <div class="wrap">
      <div class="left">
        <div class="icon">
          <img src="../assets/img/app/header-icon.jpg" alt="">
        </div>
        <div class="slogen">
          <div>山海</div>
          <div>自分らしき、武ノ型を創ろう</div>
        </div>
      </div>
      <div class="switch" @click="isShowMenu = !isShowMenu">
        <div class="text">MENU</div>
        <div class="animation">
          <span :class="{ animation1: isShowMenu }"></span>
          <span :class="{ animation2: isShowMenu }"></span>
          <span :class="{ animation3: isShowMenu }"></span>
        </div>
      </div>
    </div>
    <!-- <div class="music-wrap">
      <div
        class="music"
        :class="{ paused: paused, play: !paused }"
        @click.stop="musicControl()"
        v-if="isShowMusicState"
      >
        <img class="music" src="../assets/img/play.png" alt="" />
      </div>

      <audio :src="audioSrc" loop id="vd"></audio>
    </div> -->
    <div class="content" v-show="isShowMenu" @click="isShowMenu = !isShowMenu">
      <div class="nav">
        <router-link
          v-for="(item, key) in navList"
          :key="key"
          class="link"
          :to="item.link"
          :class="{ active: activeKey === key }"
          target="_blank"
        >
          <span class="icon"></span>
          <span>{{ item.title }}</span>
        </router-link>
        <a class="link community" href="https://sengaibbs.restargames.jp/">コミュニティ</a>
        <a class="link community" href="https://twitter.com/SENGAI18">公式Twitter</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      navList: [
        { title: "ホーム", link: "/" },
        { title: "ニュース", link: "/News" },
        { title: "特徴", link: "/Features" },
        { title: "試聴", link: "/AudioVisual" },
        { title: "資料館", link: "/PageData" },
      ],
      activeKey: 0,
      isShow: true,
      isShowMenu: false,
      audioSrc: require("../assets/audio/bg.mp3"),
      paused: true,
      isShowMusicState: false
    };
  },
  computed: mapState(["headerStatus","isShowMuisc"]),
  watch: {
    headerStatus(status) {
      this.isShowMusicState = this.$route.path.length === 1;
      if (status === 10) {
        this.isShow = false;
      } else {
        this.activeKey = status;
        if (!this.isShow) {
          this.isShow = true;
        }
      }
    },
    // isShowMuisc(status) {
      // if(status) {
      //   this.isShowMusicState = true;
      //    window.addEventListener("click", this.musicPlay);
      // } else {
      //   this.isShowMusicState = false;
      //   window.removeEventListener("click", this.musicPlay);
      // }
    // }    
  },
  methods: {
    changeMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    musicControl() {
      var music = document.getElementById("vd"); //获取ID
      if (music.paused) {
        //判读是否播放
        this.paused = false;
        music.play(); //没有就播放
        this.paused = false;
      } else {
        this.paused = true;
        music.pause();
      }
    },
    musicPlay() {
      var music = document.getElementById("vd"); //获取ID
      if (music.paused) {
        this.paused = false;
        music.play();
        window.removeEventListener("click", this.musicPlay);
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.header-conrainer {
  position: fixed;
  width: 100%;
  z-index: 30;
  display: flex;
  flex-direction: column;
  .wrap {
    width: 100%;
    height: 0.85rem;
    display: flex;
    // background-color: #2d455f;
    background-color: rgba($color: #2d455f, $alpha: 0.9);
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      height: 100%;
      // padding: .2rem;
      margin-left: .2rem;
      .icon {
        height: 0.7rem;
        margin-right: .1rem;
        img {
          height: 100%;
        }
      }
      .slogen>div:first-child {
        color: #fff;
        font-size: 0.3rem;
        font-weight: bold;
      }
      .slogen>div:last-child {
        color: #D7D7D7;
        font-size: 0.2rem;
        margin-top: .05rem;
      }
    }
  }

  .music-wrap {
    position: absolute;
    right: 0;
    bottom: -0.63rem;
  }
  .logo {
    display: block;
    height: 100%;
    margin: 0 0.18rem;
    width: 0.77rem;
    // background: url(../assets/img/logo.png) no-repeat center;
    background: url(../assets/img/bottomLogo2.png) no-repeat center;
    background-size: 200%;
  }
  .music {
    width: 0.6rem;
    height: 0.6rem;
    background: url(../assets/img/app/music.png) no-repeat center;
    background-size: contain;
    position: relative;
  }
  .play {
    animation: rotating 3s linear infinite;
  }
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .paused::after {
    content: "";
    width: 0.01rem;
    height: 0.6rem;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
  }
}
.heightUp {
  height: 100vh;
}
.switch {
  display: flex;
  // justify-content: center
  align-items: center;
  margin-right: 0.3rem;
  height: 0.8rem;
  .text {
    font-size: 0.46rem;
    color: #ffac0e;
    margin-right: 0.2rem;
    line-height: 0.5rem;
  }

  .animation {
    position: relative;
    height: 0.4rem;
    width: 0.46rem;
    span {
      // display: block;
      position: absolute;
      left: 0;
      height: 0.03rem;
      width: 0.46rem;
      background: #ffac0e;
      // margin-bottom: 0.1rem;
      transition: 0.25s ease-in-out;
    }

    .animation1 {
      top: 0.2rem;
      transform: rotate(45deg);
    }

    .animation2 {
      // display: none;
      opacity: 0;
    }

    .animation3 {
      // display: none;
      bottom: 0.17rem;
      transform: rotate(-45deg);
    }
  }
}
.animation span:nth-child(1) {
  // margin-bottom: 1px;
  top: 0;
  transform: rotate(0);
}
.animation span:nth-child(2) {
  // margin-bottom: 1px;
  top: 0.18rem;
}
.animation span:nth-child(3) {
  // margin-bottom: 1px;
  bottom: 0.03rem;
  transform: rotate(0);
}

.content {
  // position: absolute;
  // top: 1.32rem;
  // left: 0;
  // z-index: 9;
  flex: 1;
  width: 100%;

  .nav {
    text-align: center;
    background-color: rgba($color: #2d455f, $alpha: 0.8);
    padding: 1rem 0;
    .link {
      display: block;
      font-size: 0.36rem;
      color: #fff;
      line-height: 0.8rem;
      width: 60%;
      margin: 0 auto;
      border-bottom: 3px solid #9e762e;
    }
    .active {
      color: #ffac0e;
    }
  }
}
</style>