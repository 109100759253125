import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        headerStatus: 99,
        isMobile: false,
        isShowMuisc: false
    },
    mutations: {
        increment(state, { status }) {
            state.headerStatus = status
        },
        setMusic(state,{status}) {
            state.isShowMuisc = status
        },
        setIsMobile(state, { status }) {
            state.isMobile = status
        }
    }
})

export default store;