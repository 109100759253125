<template>
  <div id="app">
    <AppHeader v-if="isMobile" />
    <Header v-else />
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { isMobile, disabledScale } from "./utils/util";
import Header from "@/components/Header.vue";
import AppHeader from "@/components/AppHeader.vue";

export default {
  name: "App",
  data() {
    return {
      isMobile,
    };
  },
  components: { Header, AppHeader },
  methods:{
    ...mapMutations(["setIsMobile"]),
  },
  mounted() {
    this.setIsMobile({ status: isMobile });
    disabledScale()
  }
};
</script>

<style>
</style>
