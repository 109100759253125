<template>
  <div class="header" v-show="isShow">
    <div class="header-left">
      <div class="icon-wrap">
        <div
          class="music"
          :class="{ paused: paused, play: !paused }"
          @click.stop="musicControl()"
          v-show="isShowMusicState"
        >
          <img class="music" src="../assets/img/play.png" alt="" />
        </div>

        <audio :src="audioSrc" loop id="vd"></audio>

        <img class="logo" src="../assets/img/bottomLogo2.png" alt="" />
      </div>

      <div class="nav">
        <router-link
          v-for="(item, key) in navList"
          :key="key"
          class="link"
          :to="item.link + '?'"
          :class="{ active: activeKey === key }"
          target="_blank"
        >
          <span class="icon"></span>
          <span>{{ item.title }}</span>
        </router-link>
        <div class="link community">
          <span class="icon"></span>
          <a target="_blank" href="https://sengaibbs.restargames.jp/">コミュニティ</a>
        </div>
        
        <div class="link community">
          <span class="icon"></span>
          <a target="_blank" href="https://twitter.com/SENGAI18">公式Twitter</a>
        </div>
      </div>
    </div>

    <!-- <div class="header-right"></div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { MessageBox } from "element-ui";

export default {
  name: "Header",
  data() {
    return {
      navList: [
        { title: "ホーム", link: "/" },
        { title: "ニュース", link: "/News" },
        { title: "特徴", link: "/Features" },
        { title: "試聴", link: "/AudioVisual" },
        { title: "資料館", link: "/PageData" },
      ],
      audioSrc: require("../assets/audio/bg.mp3"),
      activeKey: 0,
      isShow: true,
      audioPlayer: null,
      paused: true,
      isShowMusicState: false
    };
  },
  computed: mapState(["headerStatus","isShowMuisc"]),
  watch: {
    headerStatus(status) {
      // console.log('status',status);
        this.isShowMusicState = this.$route.path.length === 1;
        if (status === 10) {
          this.isShow = false;
        } else {
          this.activeKey = status;
          if (!this.isShow) {
            this.isShow = true;
          }
        }
    },
    isShowMuisc(status) {
      if(status) {
        this.isShowMusicState = true;
         window.addEventListener("click", this.musicPlay);
      } else {
        this.isShowMusicState = false;
        window.removeEventListener("click", this.musicPlay);
      }
    }
  },
  methods: {
    gotoNext(link) {
      this.$router.push(link);
    },
    musicControl() {
      var music = document.getElementById("vd"); //获取ID
      console.log(music.paused);

      if (music.paused) {
        //判读是否播放
        this.paused = false;
        music.play(); //没有就播放
      } else {
        this.paused = true;
        music.pause();
      }
    },
    musicPlay() {
      var music = document.getElementById("vd"); //获取ID
      // if (music.paused && this.headerStatus) {
      if (music.paused) {
        this.paused = false;
        music.play();
        window.removeEventListener("click", this.musicPlay);
      }
    },
  },
  mounted() {
    // if(this.headerStatus === 0) {
      
    // } else {
      
    // }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #2d455e;
  background-color: rgba($color: #2d455e, $alpha: 0.9);
  height: 87px;
  padding: 0 103px;
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  z-index: 39;
  .header-left {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .icon-wrap {
      display: flex;
      align-items: center;
    }
    .music {
      // height: 28px;
      position: relative;
      margin-right: 85px;
    }
    .paused::after {
      content: "";
      width: 2px;
      height: 56px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    .logo {
      // height: 40px;
      // width: 58px;
      height: 75px;
      margin: 0 66px 0 -85px;
      // background-image: url(../assets/img/a477.png);
      // background-repeat: no-repeat;
    }
  }
  .nav {
    display: flex;
    height: 30px;
    .link {
      color: #fff;
      border-right: 2px solid #9e772d;
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 24px;
      .icon {
        display: inline-block;
        height: 11px;
        width: 11px;
        background: #9e772d;
        transform: rotate(45deg);
        margin-right: 20px;
      }
    }
    .active {
      color: #ffac0e;
      .icon {
        background: #ffac0e;
      }
    }
    .community a {
      color: #fff;
    }
  }
}
.music {
  height: 55px;
  width: 55px;
  // background: url(../assets/img/play.png) no-repeat center;
}
.play {
  animation: rotating 3s linear infinite;
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header-right {
  width: 168px;
  height: 42px;
  background-image: url(../assets/img/登陆all.png);
  background-repeat: no-repeat;
  margin-right: -50px;
  display: none;
}
</style>