import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ('../views/home/Index')
    },
    {
        path: '/AudioVisual',
        component: () =>
            import ('../views/AudioVisual')
    },
    {
        path: '/Features',
        component: () =>
            import ('../views/feature/Index')
    },
    {
        path: '/News',
        component: () =>
            import ('../views/news/Index')
    },
    {
        path: '/Introduction',
        component: () =>
            import ('../views/news/Index')
    },
    {
        path: '/NewsDetail',
        component: () =>
            import ('../views/news/Detail')
    },
    {
        path: '/NewsDetail/*',
        component: () =>
            import ('../views/news/Detail')
    },
    {
        path: '/IntroductionDetail',
        component: () =>
            import ('../views/news/Detail')
    },
    {
        path: '/IntroductionDetail/*',
        component: () =>
            import ('../views/news/Detail')
    },
    {
        path: '/PageData',
        component: () =>
            import ('../views/PageData/Index'),
        children: [

            //     {
            //     path: '',
            //     component: () =>
            //         import ('../views/PageData/child/BookList')
            // }, {
            //     path: 'BookList',
            //     component: () =>
            //         import ('../views/PageData/child/BookList')
            // }, 
            {
                path: 'Detail',
                component: () =>
                    import ('../views/PageData/child/Detail'),
            }
        ]
    },
    {
        path: '/Map',
        component: () =>
            import ('../views/PageData/child/Map')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;